<template>
    <div class="fileupload fileupload-new my-4" data-provides="fileupload">
        <input type="hidden" />
        <div
            class="fileupload-new thumbnail"
            style="width: 200px; height: 150px;"
        >
            <img
                src="/images/avatar.svg"
                alt="Avatar"
                class="rounded-circle pointer"
            />
        </div>
        <div
            class="fileupload-preview fileupload-exists thumbnail"
            style="max-width: 200px; max-height: 150px; line-height: 20px;"
        ></div>
        <div class="mt-2">
            <span class="btn btn-white btn-file">
                <span class="fileupload-new"> Cambiar Avatar</span>
                <span class="fileupload-exists"
                    ><i class="fa fa-undo"></i> Change</span
                >
                <input type="file" class="default" />
            </span>
            <a
                href="#"
                class="btn btn-danger fileupload-exists"
                data-dismiss="fileupload"
                ><i class="fa fa-trash"></i> Remove</a
            >
        </div>
    </div>
</template>

<script>
export default {
    props: { type: String, default: null }
};
</script>

<style scoped></style>

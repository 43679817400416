<template>
    <div>
        <div v-show="!mostrarPublicidad">
            <video
                ref="videoPlayer"
                class="video-js vjs-fill vjs-fluid"
                :class="videoClass"
            ></video>
        </div>
        <div v-show="mostrarPublicidad">
            <video ref="adsPlayer" class="video-js vjs-fill vjs-fluid"></video>
        </div>
    </div>
</template>

<script>
import videojs from "video.js";
import "../videojs-ad-markers/ad-markers.js";
import "../videojs-ad-markers/ad-markers.css";
export default {
    name: "VideoPlayer",
    props: {
        video: {
            type: Object,
            default: () => {}
        },
        autoplay: {
            type: Boolean,
            default: false
        },
        controls: {
            type: Boolean,
            default: true
        },
        preload: {
            type: String,
            default: "auto"
        },
        publicidades: {
            type: Array,
            default: null
        },
        aspectRatio: {
            type: String,
            default: null
        }
    },
    data() {
        return {
            player: null,
            options: {
                autoplay: this.autoplay,
                controls: this.controls,
                preload: this.preload,
                sources: [],
                poster: `/images/poster/${this.video.image}`
            },
            duration: 0,
            fullscreen: true,
            adsPlayer: null,
            mostrarPublicidad: false
        };
    },

    mounted() {
        this.prepareAdd();
        this.options.sources.push({
            src: `/storage/videos/${this.video.source}`,
            type: "video/mp4"
        });
        let vue = this;
        this.player = videojs(
            this.$refs.videoPlayer,
            this.options,
            function onPlayerReady() {
                // vue.player.requestFullscreen();
                // console.log(vue.player.requestFullscreen);
                // vue.player.log("onPlayerReady", this);
                this.on("loadedmetadata", function() {
                    vue.duration = Math.round(this.duration());
                });
                this.on("play", function(event) {
                    console.log("play!");
                    console.log(document.fullscreenEnabled);
                    vue.player.requestFullscreen();
                    // vue.player.on("play", () => {
                    // });
                });
                this.on("pause", function() {
                    vue.player.exitFullscreen();
                    console.log("video principal sale de fs!");
                });
                this.on("fullscreenchange", function(event) {
                    // console.log("cambio full");
                    // vue.fullscreen = !vue.fullscreen;
                    // // vue.adsPlayer.requestFullscreen();
                    // console.log(vue.adsPlayer.isFullscreen());
                    // vue.adsPlayer.isFullscreen(this.isFullscreen());
                    // // console.log(vue.adsPlayer);
                    // // vue.adsPlayer.isFullscreen(this.isFullscreen());
                    // console.log(vue.adsPlayer.isFullscreen());
                    // console.log(vue.player.requestFullscree  n);
                });
            }
        );
        this.player.adMarkers({
            markers: vue.markers,
            prepareNextAd: function(nextMarker) {
                vue.adsPlayer.src(nextMarker.ad);
                vue.adsPlayer.load();
                // vue.adsPlayer.ready(function() {
                //     vue.adsPlayer.on("play", function() {
                //         vue.adsPlayer.requestFullscreen();
                //     });
                //     vue.adsPlayer.on("ended", function() {
                //         vue.adsPlayer.exitFullscreen();
                //     });
                // });
            },
            playAd: function(marker) {
                vue.mostrarPublicidad = true;
                console.log("muestro la publicidad");
                console.log(document.fullscreenEnabled);
                vue.player.pause();
                if (vue.fullscreen) {
                    // await vue.player.exitFullscreen();
                    // await vue.adsPlayer.requestFullscreen();
                    // video2.requestFullscreen();
                    // } else if (video2.mozRequestFullScreen) {
                    //     video2.mozRequestFullScreen();
                    // } else if (video2.webkitRequestFullscreen) {
                    //     video2.webkitRequestFullscreen();
                    // } else if (video2.msRequestFullscreen) {
                    //     video2.msRequestFullscreen();
                    // vue.$refs.videoPlayer.requestFullscreen();
                    // vue.$refs.videoPlayer.exitFullscreen();
                    // console.log("arranca la publi en fullscreen");
                    // vue.player.isFullscreen(false);
                    // vue.$refs.adsPlayer.requestFullscreen();
                }
                vue.adsPlayer.play();
            }
        });
    },
    beforeDestroy() {
        if (this.player) {
            this.player.dispose();
        }
    },
    methods: {
        prepareAdd() {
            let vue = this;
            this.adsPlayer = videojs(
                this.$refs.adsPlayer,
                // this.options,
                {},
                function onPlayerReady() {
                    console.log("adsplayer listo!");
                    this.on("play", function() {
                        console.log("arranca la publicidad");
                        console.log(document.fullscreenEnabled);
                        // vue.adsPlayer.requestFullscreen();
                    });
                    this.on("ended", function() {
                        console.log("termina la publicidad");
                        console.log(document.fullscreenEnabled);
                        vue.mostrarPublicidad = false;
                        // vue.player.exitFullscreen();
                        vue.player.play();
                        vue.player.adMarkers.adPlaying = false; // whenever an ad ends, you must set this flag to false
                    });
                }
            );
        },
        setMarkers(markers) {
            this.player.adMarkers.reset(markers);
        }
    },
    computed: {
        markers() {
            if (!this.publicidades) {
                return this.video.advertisings_videos.map(publicidad => {
                    return {
                        time: publicidad.pivot.start,
                        ad: `/storage/videos/${publicidad.source}`
                    };
                });
            } else {
                return this.publicidades.map(publicidad => {
                    return {
                        time: publicidad.pivot.start,
                        ad: `/storage/videos/${publicidad.source}`
                    };
                });
            }
        },
        videoClass: function() {
            return {
                "vjs-32-9":
                    this.video.tipo == "3d" ||
                    this.video.tipo == "video-js-32-9",
                "vjs-24-9": this.video.tipo == "video-js-24-9",
                "video-js-360": this.video.tipo == "video-js-360"
            };
        }
    },
    watch: {
        markers(markers) {
            // console.log(newValue, oldValue);
            this.setMarkers(markers);
        },
        duration(newValue, oldValue) {},
        fullscreen(value) {
            console.log(value);
            // console.log("esta en full:" + value);
            // this.adsPlayer.isFullscreen(value);
        }
    }
};
</script>

<template>
    <div ref="parent" class="swiper-container swiper-parent">
        <div class="swiper-wrapper">
            <slot></slot>
        </div>
        <div ref="prev" class="swiper-button-prev">
            <i class="icon-angle-left"></i>
        </div>
        <div ref="next" class="swiper-button-next">
            <i class="icon-angle-right"></i>
        </div>
    </div>
</template>
<script>
import Swiper from "swiper";
import "swiper/css";
import "swiper/css/navigation";
export default {
    props: {
        direction: { default: "horizontal" },
        speed: { default: 300 },
        autoplay: { default: 999999999 },
        loop: { default: false },
        slidesPerView: { default: 1 },
        effect: { default: "slide" },
        grabCursor: { default: true },
        fullscreen: { default: false },
    },
    mounted() {
        new Swiper(this.$refs.parent, {
            direction: this.direction,
            speed: Number(this.speed),
            autoplay: {
                delay: Number(this.autoplay),
            },
            loop: this.loop,
            effect: this.effect,
            slidesPerView: Number(this.slidesPerView),
            spaceBetween: 30,
            grabCursor: this.grabCursor,
            // pagination: {
            //     el: Pagination,
            //     clickable: PaginationClickable
            // },
            navigation: {
                prevEl: this.$refs.prev,
                nextEl: this.$refs.next,
            },
        });
    },
};
</script>

<template>
    <div>
        <p
            class="bold ms-4 me-3 mb-0"
            v-text="label"
            sytle="white-space: nowrap;"
        />
        <input
            :name="name"
            :type="type"
            :value="value"
            @input="$emit('input', $event.target.value)"
            class="py-2 text-dark text-uppercase bold form-control"
            :step="step"
            :id="name"
            :placeholder="placeholder"
            :style="type == 'number' ? 'max-width:130px' : ''"
        />
    </div>
</template>

<script>
export default {
    props: {
        name: {
            type: String,
            default: "name",
        },
        label: {
            type: String,
            default: "Name",
        },
        value: {
            default: "",
        },
        type: {
            type: String,
            default: "text",
        },
        placeholder: {
            type: String,
            default: "",
        },
        step: {
            type: Number,
            default: 0,
        },
    },
};
</script>

<style lang="scss" scoped></style>

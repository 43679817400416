window.onload = function() {
    var swiper = new Swiper(".swiper-container", {
        spaceBetween: 30,
        loop: true,
        autoplay: {
            delay: 5000
        },
        pagination: {
            el: ".swiper-pagination",
            clickable: true
        },
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev"
        }
    });
    var swiper2 = new Swiper(".sc1", {
        slidesPerView: 1,
        spaceBetween: 23,
        centeredSlides: false,
        slidesPerGroupSkip: 1,
        loop: true,
        grabCursor: true,
        keyboard: { enabled: true },
        breakpoints: {
            768: { slidesPerView: 2, slidesPerGroup: 1, spaceBetween: 23 },
            992: { slidesPerView: 4, slidesPerGroup: 1, spaceBetween: 25 },
            1200: { slidesPerView: 4, slidesPerGroup: 1, spaceBetween: 50 }
        },
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev"
        }
    });
    let swiperCanales = new Swiper(".swiper-canal", {
        slidesPerGroupSkip: 1,
        grabCursor: true,
        loop: true,
        keyboard: { enabled: true },
        breakpoints: {
            768: { slidesPerView: 2, slidesPerGroup: 1, spaceBetween: 23 },
            992: { slidesPerView: 4, slidesPerGroup: 1, spaceBetween: 25 },
            1200: { slidesPerView: 5, slidesPerGroup: 1, spaceBetween: 50 }
        }
    });

    var cant = Array.from(document.querySelectorAll(".swiper-container2"))
        .length;
    for (let i = 2; i <= cant + 1; i++) {
        var swiper3 = new Swiper(".sc" + i, {
            slidesPerView: 1,
            spaceBetween: 23,
            centeredSlides: false,
            slidesPerGroupSkip: 1,
            grabCursor: true,
            loop: true,
            keyboard: { enabled: true },
            breakpoints: {
                768: { slidesPerView: 2, slidesPerGroup: 1, spaceBetween: 23 },
                992: { slidesPerView: 4, slidesPerGroup: 1, spaceBetween: 25 },
                1200: { slidesPerView: 5, slidesPerGroup: 1, spaceBetween: 50 }
            },
            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev"
            }
        });
    }

    let grid = document.querySelector(".grid");
    if (grid) {
        let iso = new Isotope(grid, {
            itemSelector: ".grid-item",
            layoutMode: "fitRows"
        });
        let botones = document.querySelectorAll("#filtros a");
        botones.forEach(element => {
            element.onclick = function(e) {
                e.preventDefault();
                let filterValue = element.dataset.filter;
                iso.arrange({
                    filter: filterValue
                });
            };
        });
    }

    let videos3d = document.querySelectorAll(".video-js-360");
    videos3d.forEach(element => {
        let player = (window.player = videojs(element.id));
        player.vr({ projection: "360", debug: true, forceCardboard: false });
    });
};

<template>
    <div class="d-inline-block">
        <a
            class="sociallink align-middle text-decoration-none"
            @click.prevent="showCode"
            ><i class="fas fa-2x fa-code"></i
        ></a>

        <Transition name="bounce">
            <div class="border rounded p-3 mt-3" v-if="show">
                {{ embedCode }}
                <button
                    v-clipboard:copy="embedCode"
                    v-clipboard:success="onCopy"
                    class="btn btn-xs btn-primary"
                >
                    Copiar
                </button>
            </div>
        </Transition>
    </div>
</template>

<script>
export default {
    props: {
        video: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {
            show: false,
            embedCode: "",
            urlVideo: `${Sistema.app_url}/images/poster/${this.video.image}`,
            posterVideo: `${Sistema.app_url}/storage/videos/${this.video.source}`,
        };
    },
    methods: {
        showCode() {
            this.show = !this.show;
        },
        onCopy() {
            toastr.success("Copiado!");
        },
    },
    mounted() {
        this.embedCode = `<video controls autoplay muted poster="${this.urlVideo}" preload="none"><source src="${this.posterVideo}" type="video/mp4"/></video>`;
    },
};
</script>

<style lang="scss" scoped>
.bounce-enter-active {
    animation: bounce-in 0.5s;
}
.bounce-leave-active {
    animation: bounce-in 0.5s reverse;
}
@keyframes bounce-in {
    0% {
        transform: scale(0);
    }
    50% {
        transform: scale(1.25);
    }
    100% {
        transform: scale(1);
    }
}
</style>

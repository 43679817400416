<template>
    <ckeditor
        :editor="editor"
        v-model="editorData"
        :config="editorConfig"
        @input="onEditorInput"
        @ready="onEditorReady"
    ></ckeditor>
</template>

<script>
import CKEditor from "@ckeditor/ckeditor5-vue2";
import "@ckeditor/ckeditor5-build-classic/build/translations/es";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
class UploadAdapter {
    constructor(loader, url) {
        this.loader = loader;
        this.url = url;
    }
    upload() {
        return new Promise((resolve, reject) => {
            const data = new FormData();
            data.append("nombre", this.loader.file.name);
            data.append("archivo", this.loader.file);
            axios
                .post(this.url, data)
                .then(response => {
                    resolve({
                        default: "/images/grande/" + response.data.archivo
                    });
                })
                .catch(error => {
                    reject(error);
                });
        });
    }
    abort() {
        // Se maneja la exception desde el upload
    }
}

function UploadAdapterPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = loader => {
        return new UploadAdapter(loader, route("uploadArchivoPagina"));
    };
}

export default {
    components: {
        ckeditor: CKEditor.component
    },
    name: "Editor",
    props: {
        contenido: { default: "" },
        url: { default: "" },
        tipo: { default: "full" }
    },
    data() {
        return {
            editorData: "",
            editor: ClassicEditor,
            editorConfig: {
                extraPlugins: [UploadAdapterPlugin],
                toolbar:
                    this.tipo == "basico"
                        ? [
                              "bold",
                              "italic",
                              "bulletedList",
                              "numberedList",
                              "blockQuote"
                          ]
                        : [
                              "heading",
                              "|",
                              "bold",
                              "italic",
                              "link",
                              "bulletedList",
                              "numberedList",
                              "imageUpload",
                              "blockQuote",
                              "insertTable",
                              "undo",
                              "redo"
                          ],
                language: "es"
            }
        };
    },
    methods: {
        onEditorInput() {
            this.$emit("update:contenido", this.editorData);
        },
        onEditorReady() {
            this.editorData = this.contenido;
        }
    },
    mounted() {},
    created() {},
    computed: {
        algo() {
            return this.contenido;
        }
    }
};
</script>

<style>
.ck.ck-content ul {
    padding-left: 4.5rem;
}
.ck.ck-content ul li {
    list-style: disc;
}
</style>

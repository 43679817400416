// window._ = require('lodash');
// import Swiper from 'swiper';


import jQuery from 'jquery'
window.$ = window.jQuery = jQuery;

import 'bootstrap';

window.axios = require('axios');

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

import vjs from 'video.js';
window.videojs = vjs;
require('videojs-vr');

import Isotope from 'isotope-layout';
window.Isotope = Isotope

import Toastr from 'toastr'

window.toastr = Toastr;
window.toastr.options.timeOut = 5000;
window.toastr.options.positionClass = "toast-bottom-right";
window.toastr.options.progressBar = true;

// window.Swiper = Swiper;

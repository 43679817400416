<template>
    <section class="p-2 p-sm-3 p-md-5 col-xl-10 mx-auto">
        <h5 class="text-info bold text-uppercase">
            Subir Video
        </h5>
        <div
            v-if="!cargando"
            class="bg-subir-video py-5 rounded text-center"
            @dragenter.prevent
            @dragover.prevent
            @drop.prevent="handleFile"
        >
            <div class="text-center col-6 col-sm-4 col-md-3 col-xl-2 mx-auto">
                <img
                    src="/images/subirvideo.svg"
                    alt=""
                    class="img-fluid  pointer"
                />
            </div>
            <p class="text-dark bold mt-3">
                Arrastrá y soltá el archivo que querés subir
            </p>
            <div
                class="bg-info position-relative py-2 pointer col-10 col-sm-6 col-md-4 col-xl-3 mx-auto"
            >
                <span class="text-uppercase text-white bold pointer"
                    >seleccione un archivo</span
                >
                <input
                    ref="dropzoneFile"
                    type="file"
                    name="video"
                    id="video"
                    class="absolute-top subirarchivo"
                    @change="handleFile"
                />
            </div>
        </div>
        <div
            v-else
            class="bg-subir-video py-5 rounded text-center container-fluid"
        >
            <div class="row" v-if="body.source">
                <h4>Se ha subido su video!</h4>
                <div class="col-md-6 mb-2">
                    <video
                        ref="video"
                        class="video-js vjs-fill vjs-fluid"
                        controls
                        preload="auto"
                        data-setup="{height:400px}"
                    >
                        <source
                            :src="`/storage/videos/${body.source}`"
                            type="video/mp4"
                        />
                    </video>
                </div>
                <div class="col-md-6 mb-2">
                    <img
                        :src="body.image"
                        alt="Imagen de portada"
                        class="img-fluid rounded-3"
                    />
                    <p class="mb-2">
                        Pause el video en el lugar deseado para capurar la
                        imagen de portada
                    </p>
                    <button class="btn btn-primary" @click="capturarImagen">
                        Capturar Imagen de portada
                    </button>
                    <span class="px-2">o</span>
                    <span class="btn btn-info fileinput-button">
                        <span
                            ><i class="fa fa-cloud-upload"></i> Cargar una
                            imagen</span
                        >
                        <input type="file" @change="subirImagen" />
                    </span>
                </div>
            </div>
            <div class="row py-2" v-else>
                <div class="col-12">
                    <h4>
                        Estamos subiendo el video<br />
                        <i class="fas fa-spinner fa-spin fa-lg"></i>
                    </h4>
                </div>
                <div class="col-12 col-md-8 col-lg-6 my-2 mx-auto">
                    <p class="mb-0 text-center">{{ uploadProgress }}%</p>
                    <div class="bg-white p-2">
                        <div
                            class="bg-success p-3 btn btn-block"
                            :style="{ width: `${uploadProgress}%` }"
                        ></div>
                    </div>
                </div>
            </div>
        </div>
        <form @submit.prevent="onSubmit" class="container text-white mt-4">
            <div class="row flex-wrap align-items-center  mb-lg-4">
                <div
                    class="col-12 col-lg-5 d-flex flex-wrap align-items-center"
                >
                    <h5 class="col-12 col-lg-3 bold my-auto">Título</h5>
                    <div class="col-12 col-lg-9">
                        <input
                            type="text"
                            name="nombre"
                            v-model="body.name"
                            class="py-2 text-dark text-uppercase bold form-control"
                        />
                    </div>
                </div>
                <div class="col-12 col-lg-2 "></div>
                <div class="col-lg-5  d-flex flex-wrap align-items-center">
                    <h5 class="col-12 col-lg-3 bold my-auto">Tipo</h5>
                    <div class="col-12 col-lg-9">
                        <select
                            id="tipo"
                            v-model="body.tipo"
                            class="form-select"
                        >
                            <option value="normal">Normal</option>
                            <option value="video-js-32-9">Wide</option>
                            <option value="video-js-24-9">Cine</option>
                            <option value="video-js-360">360°</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="row" v-if="esAdmin">
                <div
                    class=" d-flex align-items-center justify-content-center my-2"
                >
                    <div class="form-check">
                        <input
                            class="form-check-input me-3"
                            type="checkbox"
                            v-model="body.adv"
                            id="adv"
                            style="transform: scale(1.7);"
                        />
                        <label class="form-check-label" for="adv">
                            Es una publicidad?
                        </label>
                    </div>
                </div>
            </div>
            <div
                class="d-flex flex-wrap align-items-center my-lg-5"
                v-if="!body.adv"
            >
                <div class="col-12 col-lg-5 d-flex flex-wrap mb-3 mb-lg-0">
                    <p class="col-12 col-lg-3 bold my-auto">Categoria</p>
                    <div class="col-12 col-lg-9">
                        <select
                            name="categoria"
                            v-model="categoria_id"
                            class="form-select"
                        >
                            <option value="">Elegir una categoría</option>
                            <option
                                v-for="categoria in categorias"
                                :key="categoria.id"
                                :value="categoria.id"
                                >{{ categoria.name }}</option
                            >
                        </select>
                    </div>
                </div>
                <div
                    class="col-12 col-lg-5 d-flex flex-wrap mb-3 mb-lg-0 ms-lg-3"
                >
                    <p class="col-12 col-lg-4 bold my-auto">Subcategoria</p>
                    <div class="col-12 col-lg-8">
                        <select
                            v-model="body.subcategory_id"
                            class="form-select"
                        >
                            <option value="">Elegir subcategoría</option>
                            <option
                                :value="subcategoria.id"
                                v-for="subcategoria in subcategorias"
                                :key="subcategoria.id"
                                >{{ subcategoria.name }}</option
                            >
                        </select>
                    </div>
                </div>
            </div>

            <div v-if="tieneProgramas">
                <div class="d-flex justify-content-center my-3 my-lg-5">
                    <div class="form-check">
                        <input
                            class="form-check-input me-3"
                            type="checkbox"
                            v-model="programa"
                            id="programa"
                            style="transform: scale(1.7);"
                        />
                        <label class="form-check-label" for="programa">
                            Es un programa?
                        </label>
                    </div>
                </div>
                <transition name="slide-fade">
                    <div
                        class="row g-3 flex-wrap align-items-center mb-3  mx-auto"
                        v-if="programa"
                    >
                        <div class="col-md-5">
                            <div class="row mb-3">
                                <label
                                    for="programa"
                                    class="col-sm-2 col-form-label"
                                    >Programa</label
                                >
                                <div class="col-sm-10">
                                    <select
                                        id="programa"
                                        v-model="programa_id"
                                        class="form-select"
                                    >
                                        <option value=""
                                            >Elegir Programa</option
                                        >
                                        <option
                                            v-for="programa in programas"
                                            :key="programa.id"
                                            :value="programa.id"
                                            >{{ programa.name }}</option
                                        >
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class=" row mb-3">
                                <label
                                    for="temporada"
                                    class="col-sm-3 col-form-label"
                                    >Temporada</label
                                >
                                <div class="col-sm-9">
                                    <select
                                        id="temporada"
                                        v-model="body.season_id"
                                        class="form-select"
                                        @change="eligioTemporada"
                                    >
                                        <option value=""
                                            >Elegir Temporada</option
                                        >
                                        <option v-if="programa_id" value="new"
                                            >Temporada Nueva</option
                                        >
                                        <option
                                            v-for="season in seasons"
                                            :key="season.id"
                                            :value="season.id"
                                            >{{ season.name }}</option
                                        >
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3 ">
                            <div class="row mb-3">
                                <label
                                    for="episode"
                                    class="col-sm-3 col-form-label"
                                    >Episodio</label
                                >
                                <div class="col-sm-9">
                                    <input
                                        type="number"
                                        class="form-control"
                                        id="episode"
                                        v-model="body.episode"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </transition>
            </div>

            <div class="d-flex justify-content-center my-3 my-lg-5">
                <div class="form-check">
                    <input
                        class="form-check-input me-3"
                        type="checkbox"
                        v-model="body.es_pago"
                        id="es_pago"
                        style="transform: scale(1.7);"
                    />
                    <label class="form-check-label" for="es_pago">
                        Quieres vender este video?
                    </label>
                </div>
            </div>
            <transition name="slide-fade">
                <div
                    class="row g-3 flex-wrap align-items-center justify-content-center mb-3  mx-auto"
                    v-if="body.es_pago"
                >
                    <fl-input
                        label="Precio"
                        v-model.number="body.precio"
                        type="number"
                        class="col-12 col-lg-3 d-flex align-items-center justify-content-center"
                        name="precio"
                        :step="0.01"
                    />
                    <fl-input
                        label="Visualizaciones"
                        v-model.number="body.visualizaciones"
                        type="number"
                        :step="1"
                        name="visualizaciones"
                        class="col-12 col-lg-3 d-flex align-items-center justify-content-center"
                    />
                    <fl-input
                        label="Días disponible"
                        v-model.number="body.dias_disponible"
                        type="number"
                        :step="1"
                        name="visualizaciones"
                        class="col-12 col-lg-3 d-flex align-items-center justify-content-center"
                    />
                </div>
            </transition>

            <div class="col-12 mt-4 text-dark ">
                <editor
                    id="texto"
                    class="border-2x border-white rounded-textarea"
                    :contenido.sync="body.description"
                    tipo="basico"
                />
            </div>
            <div class="d-flex justify-content-end my-5">
                <button
                    class="px-3 py-2 btn-success border-success"
                    type="submit"
                    :disabled="guardando"
                >
                    <i class="fas fa-spinner fa-spin" v-if="guardando"></i>
                    <img src="/images/check.svg" alt="" width="20px" v-else />
                    <span
                        class="ms-2 p-1 px-2 border-start border-white text-white text-uppercase bold"
                    >
                        crear
                    </span>
                </button>
            </div>
        </form>
        <canvas ref="canvas" class="d-none"></canvas>
        <modal name="new_season" :adaptive="true" @before-close="beforeClose">
            <div class="card h-100">
                <div class="card-body">
                    <h5 class="card-title mb-4">Crear una nueva temporada</h5>
                    <div class="row mb-3">
                        <label for="season-name" class="col-sm-2 col-form-label"
                            >Nombre</label
                        >
                        <div class="col-sm-10">
                            <input
                                v-model="newSeason.name"
                                type="text"
                                class="form-control"
                                id="season-name"
                            />
                        </div>
                    </div>
                    <div class="row mb-3">
                        <label
                            for="season-number"
                            class="col-sm-2 col-form-label"
                            >Numero</label
                        >
                        <div class="col-sm-4">
                            <input
                                v-model="newSeason.number"
                                type="number"
                                class="form-control"
                                id="season-number"
                            />
                        </div>
                        <label for="season-year" class="col-sm-2 col-form-label"
                            >Año</label
                        >
                        <div class="col-sm-4">
                            <input
                                v-model="newSeason.year"
                                type="text"
                                class="form-control"
                                id="season-year"
                            />
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-sm-2"></div>
                        <div class="form-check col-sm-10">
                            <input
                                class="form-check-input"
                                type="checkbox"
                                v-model="newSeason.active"
                                id="season-active"
                            />
                            <label class="form-check-label" for="season-active">
                                Activar
                            </label>
                        </div>
                    </div>
                </div>
                <div class="card-footer text-end">
                    <button
                        href="#"
                        class="btn btn-secondary"
                        @click="$modal.hide('new_season')"
                    >
                        Cerrar
                    </button>
                    <button
                        href="#"
                        class="btn btn-primary"
                        @click="crearTemporada"
                    >
                        Crear
                    </button>
                </div>
            </div>
        </modal>
    </section>
</template>

<script>
export default {
    name: "VideoUpload",
    data() {
        return {
            urlSubir: "/video/uploadArchivo",
            urlSubirPoster: "/video/uploadPoster",
            uploadProgress: 0,
            body: {
                source: "",
                image: "/images/portada.svg",
                name: "",
                adv: false,
                subcategory_id: "",
                season_id: "",
                episode: "",
                description: "",
                duration: "",
                tipo: "normal",
                es_pago: true,
                precio: "",
                visualizaciones: "",
                dias_disponible: ""
            },
            newSeason: {
                name: "",
                number: "",
                year: new Date().getFullYear(),
                active: true
            },
            categorias: [],
            programas: [],
            programa: false,
            categoria_id: "",
            programa_id: "",
            cargando: false,
            imageSource: "",
            user: Sistema.user,
            guardando: false
        };
    },
    mounted() {
        this.getCategorias();
        this.getProgramas();
    },
    methods: {
        onSubmit() {
            this.guardando = true;
            axios
                .post("/video", { ...this.body })
                .then(response => {
                    toastr.success(response.data.mensaje);
                    location.href = "/perfil";
                    this.guardando = false;
                })
                .catch(e => {
                    console.log(e.response);
                    this.guardando = false;
                });
        },
        handleFile(e) {
            this.cargando = true;
            let inputFile =
                e.target.files ||
                e.dataTransfer.files ||
                this.$refs.dropzoneFile.files;
            if (inputFile.length == 1) {
                console.log(inputFile[0]);
                this.uploadFile(inputFile[0]);
            } else {
                toastr.warning("Solo puede subir un video a la vez!");
            }
        },
        uploadFile(file) {
            let data = new FormData();
            data.append("archivo", file);
            axios
                .post(this.urlSubir, data, {
                    onUploadProgress: progressEvent => {
                        const total = progressEvent.total;
                        const totalLength = progressEvent.lengthComputable
                            ? total
                            : null;
                        if (totalLength !== null) {
                            this.uploadProgress = parseInt(
                                Math.round(progressEvent.loaded * 100) / total
                            );
                        }
                    }
                })
                .then(response => {
                    this.body.source = response.data.archivo;
                    toastr.success(response.data.mensaje);
                })
                .catch(e => {
                    this.cargando = false;
                    toastr.error(e.response.data.errors.archivo[0]);
                });
        },
        subirImagen(e) {
            this.cargando = true;
            let files = e.target.files || e.dataTransfer.files;
            if (!files.length) return;
            this.createImage(files[0]);
            console.log("subiendo");
        },
        createImage(file) {
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = e => {
                this.body.image = e.target.result;
                this.uploadPoster(file);
            };
        },
        capturarImagen() {
            let video = this.$refs.video;
            let canvas = this.$refs.canvas;
            canvas.width = video.videoWidth;
            canvas.height = video.videoHeight;
            canvas
                .getContext("2d")
                .drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
            this.body.image = canvas.toDataURL("image/jpeg");
            this.uploadPoster();
        },
        uploadPoster() {
            let data = new FormData();
            data.append("archivo", this.dataURItoBlob(this.body.image));
            axios
                .post(this.urlSubirPoster, data)
                .then(response => {
                    this.body.image = "/images/poster/" + response.data.archivo;
                    toastr.success(response.data.mensaje);
                })
                .catch(e => {
                    toastr.error(e.response.data.errors.archivo[0]);
                });
        },
        dataURItoBlob(dataURI) {
            // convert base64/URLEncoded data component to raw binary data held in a string
            var byteString;
            if (dataURI.split(",")[0].indexOf("base64") >= 0)
                byteString = atob(dataURI.split(",")[1]);
            else byteString = unescape(dataURI.split(",")[1]);
            var mimeString = dataURI
                .split(",")[0]
                .split(":")[1]
                .split(";")[0];
            var ia = new Uint8Array(byteString.length);
            for (var i = 0; i < byteString.length; i++) {
                ia[i] = byteString.charCodeAt(i);
            }
            return new Blob([ia], { type: mimeString });
        },
        getCategorias() {
            axios
                .get("/video/getCategorias")
                .then(res => (this.categorias = res.data))
                .catch(e => console.log(e.response));
        },
        getProgramas() {
            axios
                .get("/video/getProgramas")
                .then(res => (this.programas = res.data))
                .catch(e => console.log(e.response));
        },
        eligioTemporada() {
            if (this.body.season_id == "new") {
                this.$modal.show("new_season");
            } else {
                let season = this.seasons.find(
                    season => season.id == this.body.season_id
                );
                if (season) {
                    this.body.episode = season.siguiente_episodio;
                }
            }
        },
        beforeClose() {
            if (this.body.season_id == "new") {
                this.body.season_id = "";
            }
        },
        crearTemporada() {
            axios
                .post("/temporadas/" + this.programaSlug, { ...this.newSeason })
                .then(response => {
                    this.getProgramas();
                    toastr.success(response.data.mensaje);
                    this.$modal.hide("new_season");
                    this.body.season_id = response.data.season.id;
                })
                .catch(e => {});
        }
    },
    computed: {
        esAdmin() {
            return this.user.role_id == 1;
        },
        subcategorias() {
            return this.categoria_id
                ? this.categorias.find(cat => cat.id == this.categoria_id)
                      .subcategorias
                : [];
        },
        seasons() {
            return this.programa_id
                ? this.programas.find(prog => prog.id == this.programa_id)
                      .seasons
                : [];
        },
        tieneProgramas() {
            return this.programas.length > 0;
        },
        programaSlug() {
            if (this.programa_id) {
                return this.programas.find(prog => prog.id == this.programa_id)
                    .slug;
            }
        },
        videoClass: function() {
            return {
                "vjs-32-9":
                    this.body.tipo == "3d" || this.body.tipo == "video-js-32-9",
                "vjs-24-9": this.body.tipo == "video-js-24-9",
                "video-js-360": this.body.tipo == "video-js-360"
            };
        }
    },
    watch: {
        body: {
            handler: function(value) {
                if (value.adv) {
                    (this.categoria_id = ""), (this.body.subcategory_id = "");
                }
            },
            deep: true
        }
    }
};
</script>
<style scoped>
/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-enter-active {
    transition: all 0.5s ease;
}
.slide-fade-leave-active {
    transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
    transform: translateX(10px);
    opacity: 0;
}
</style>

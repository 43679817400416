<template>
    <section class="p-2 p-sm-3 p-md-5 col-xl-10 mx-auto">
        <h5 class="text-info bold text-uppercase">video {{ video.name }}</h5>
        <div class="bg-subir-video py-5 rounded text-center container">
            <div class="col-md-6 mx-auto">
                <video-player
                    :video="video"
                    :publicidades.sync="publicidades"
                ></video-player>
                <!-- <video
                    ref="video"
                    class="video-js vjs-fill vjs-fluid"
                    controls
                    preload="auto"
                    data-setup="{ }"
                    :poster="`/images/poster/${video.image}`"
                >
                    <source
                        :src="`/storage/videos/${video.source}`"
                        type="video/mp4"
                    />
                </video> -->
            </div>
        </div>

        <div class="container text-white mt-4">
            <h5 class="text-info bold text-uppercase">
                Publicidades
            </h5>
            <div class="text-end mb-3">
                <button
                    class="btn btn-primary"
                    @click="$modal.show('crear-publicidad')"
                >
                    Agregar una publicidad
                </button>
            </div>
            <div class="row" v-if="publicidades.length">
                <table class="table table-dark table-striped">
                    <thead>
                        <tr>
                            <th>Publicidad</th>
                            <th>Status</th>
                            <th class="text-center" width="70px">Inicio</th>
                            <th class="text-center" width="75px">
                                Acciones
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            v-for="publicidad of publicidades"
                            :key="publicidad.pivot.id"
                        >
                            <td>{{ publicidad.name }}</td>
                            <td>{{ publicidad.status }}</td>
                            <td class="text-center">
                                {{ publicidad.pivot.start }} s
                            </td>
                            <td class="text-center">
                                <!-- <i
                                    class="fas fa-pen text-primary"
                                    @click="editPublicidad(publicidad)"
                                ></i> -->
                                <i
                                    class="ms-2 fas fa-trash text-danger"
                                    @click="borrarPublicidad(publicidad)"
                                ></i>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div v-else class="alert alert-warning" role="alert">
                Este video no tiene publicidades
            </div>
        </div>
        <modal
            name="crear-publicidad"
            @before-close="beforeClose"
            height="auto"
        >
            <div class="modal-header">
                <h5 class="modal-title">Agregar una publicidad</h5>
                <button
                    type="button"
                    class="close btn btn-link"
                    @click="$modal.hide('crear-publicidad')"
                >
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <form>
                    <div class="form-group row mb-3">
                        <label for="publicidad" class="col-sm-4 col-form-label"
                            >Publicidad</label
                        >
                        <div class="col-sm-8">
                            <select
                                class="form-control"
                                id="publicidad"
                                v-model="body.advertising_id"
                            >
                                <option value="">Elegir publicidad</option>
                                <option
                                    v-for="publicidad in todasLasPublicidades"
                                    :key="publicidad.id"
                                    :value="publicidad.id"
                                    >{{ publicidad.name }}</option
                                >
                            </select>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label for="staticEmail" class="col-sm-4 col-form-label"
                            >Inicio</label
                        >
                        <div class="col-sm-8">
                            <input
                                type="number"
                                class="form-control"
                                id="staticEmail"
                                v-model.number="body.start"
                                min="0"
                                placeholder="Inicio en segundo"
                            />
                        </div>
                    </div>
                </form>
            </div>
            <div class="modal-footer">
                <button
                    type="button"
                    class="btn btn-secondary"
                    @click="$modal.hide('crear-publicidad')"
                >
                    Cerrar
                </button>
                <button
                    type="button"
                    class="btn btn-primary"
                    @click="createSubmit"
                >
                    Crear
                </button>
            </div>
        </modal>
        <modal name="editar-publicidad" @before-close="beforeClose">
            editar Publicidad {{ publicidadElegida.name }}
        </modal>
        <modal
            name="borrar-publicidad"
            @before-close="beforeClose"
            height="auto"
        >
            <div class="modal-header">
                <h5 class="modal-title">
                    Borrar publicidad {{ publicidadElegida.name }}
                </h5>
                <button
                    type="button"
                    class="close btn btn-link"
                    @click="$modal.hide('borrar-publicidad')"
                >
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                Esta seguro que quiere borrar esta publicidad?
            </div>
            <div class="modal-footer">
                <button
                    type="button"
                    class="btn btn-secondary"
                    @click="$modal.hide('borrar-publicidad')"
                >
                    Cerrar
                </button>
                <button
                    type="button"
                    class="btn btn-primary"
                    @click="destroySubmit"
                >
                    Borrar
                </button>
            </div>
        </modal>
    </section>
</template>

<script>
import VideoUpload from "./VideoUpload.vue";
export default {
    components: { VideoUpload },
    props: {
        video: {
            type: Object,
            default: {}
        }
    },
    data() {
        return {
            publicidades: [],
            publicidadElegida: {},
            todasLasPublicidades: [],
            body: {
                advertising_id: "",
                advertising_type: "App\Models\Video",
                start: 0,
                stop: 0
            }
        };
    },
    methods: {
        editPublicidad(value) {
            this.publicidadElegida = value;
            this.$modal.show("editar-publicidad");
        },
        borrarPublicidad(value) {
            this.publicidadElegida = value;
            this.$modal.show("borrar-publicidad");
        },
        createSubmit() {
            axios
                .post(`/gestion/storePublicidad/${this.video.slug}`, {
                    ...this.body
                })
                .then(res => {
                    this.publicidades = res.data.video.advertisings_videos;
                    this.$modal.hide("crear-publicidad");
                    toastr.success(res.data.mensaje);
                })
                .catch(e => console.log(e.response.data));
        },
        destroySubmit() {
            axios
                .delete(
                    `/gestion/destroyPublicidad/${this.publicidadElegida.pivot.id}`
                )
                .then(res => {
                    this.publicidades = res.data.video.advertisings_videos;
                    this.$modal.hide("borrar-publicidad");
                    toastr.success(res.data.mensaje);
                });
        },
        beforeClose() {
            this.publicidadElegida = {};
            this.body.advertising_id = "";
            this.body.start = 0;
        },
        getPublicidades() {
            axios
                .get("/gestion/getPublicidades")
                .then(res => (this.todasLasPublicidades = res.data))
                .catch(e => console.log(e.response.data));
        }
    },
    mounted() {
        this.publicidades = this.video.advertisings_videos;
        this.getPublicidades();
        // console.log(this.$refs.video);
    }
};
</script>

<style scoped></style>

<template>
    <div>
        <div v-show="!mostrarPublicidad">
            <video
                ref="videoPlayer"
                class="video-js vjs-fill vjs-fluid"
                :class="videoClass"
            ></video>
        </div>
        <div v-show="mostrarPublicidad">
            <video ref="adsPlayer" class="video-js vjs-fill vjs-fluid"></video>
        </div>
    </div>
</template>

<script>
import videojs from "video.js";
import "../videojs-ad-markers/ad-markers.js";
import "../videojs-ad-markers/ad-markers.css";
export default {
    name: "VideoPago",
    props: {
        video: {
            type: Object,
            default: () => {}
        },
        autoplay: {
            type: Boolean,
            default: false
        },
        controls: {
            type: Boolean,
            default: true
        },
        preload: {
            type: String,
            default: "auto"
        },
        publicidades: {
            type: Array,
            default: null
        },
        aspectRatio: {
            type: String,
            default: null
        },
        url: {
            type: String,
            default: null
        }
    },
    data() {
        return {
            player: null,
            options: {
                autoplay: this.autoplay,
                controls: this.controls,
                preload: this.preload,
                sources: [],
                poster: `/images/poster/${this.video.image}`
            },
            duration: 0,
            adsPlayer: null,
            mostrarPublicidad: false
        };
    },

    mounted() {
        this.prepareAdd();
        this.options.sources.push({
            src: this.url ?? this.video.pivot.url_temporaria,
            type: "video/mp4"
        });
        let vue = this;
        this.player = videojs(
            this.$refs.videoPlayer,
            this.options,
            function onPlayerReady() {
                this.on("loadedmetadata", function() {
                    vue.duration = Math.round(this.duration());
                });
            }
        );
        this.player.adMarkers({
            markers: vue.markers,
            prepareNextAd: function(nextMarker) {
                vue.adsPlayer.src(nextMarker.ad);
                vue.adsPlayer.load();
            },
            playAd: function(marker) {
                vue.mostrarPublicidad = true;
                vue.player.pause();
                vue.adsPlayer.play();
            }
        });
    },
    beforeDestroy() {
        if (this.player) {
            this.player.dispose();
        }
    },
    watch: {
        duration(newValue, oldValue) {}
    },
    methods: {
        prepareAdd() {
            let vue = this;
            this.adsPlayer = videojs(
                this.$refs.adsPlayer,
                {},
                function onPlayerReady() {
                    this.on("ended", function() {
                        vue.mostrarPublicidad = false;
                        vue.player.play();
                        vue.player.adMarkers.adPlaying = false; // whenever an ad ends, you must set this flag to false
                    });
                }
            );
        },
        setMarkers(markers) {
            this.player.adMarkers.reset(markers);
        }
    },
    computed: {
        markers() {
            if (!this.publicidades) {
                return this.video.advertisings_videos.map(publicidad => {
                    return {
                        time: publicidad.pivot.start,
                        ad: `/storage/videos/${publicidad.source}`
                    };
                });
            } else {
                return this.publicidades.map(publicidad => {
                    return {
                        time: publicidad.pivot.start,
                        ad: `/storage/videos/${publicidad.source}`
                    };
                });
            }
        },
        videoClass: function() {
            return {
                "vjs-32-9":
                    this.video.tipo == "3d" ||
                    this.video.tipo == "video-js-32-9",
                "vjs-24-9": this.video.tipo == "video-js-24-9",
                "video-js-360": this.video.tipo == "video-js-360"
            };
        }
    },
    watch: {
        markers(markers) {
            // console.log(newValue, oldValue);
            this.setMarkers(markers);
        }
    }
};
</script>
